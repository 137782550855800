<template>
  <v-app>
    <v-card flat>
      <v-toolbar
        color="teal accent-4"
        dark
        flat
        extended
      >
        <v-row align="center">
          <v-col class="text-center display-2">
            <b>{{ event ? event.name : '-' }}</b>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-card
        :loading="loadingEvent"
        class="mx-auto"
        max-width="700"
        style="margin-top: -49px;"
      >
        <template slot="progress">
          <v-progress-linear color="teal" indeterminate />
        </template>
        <router-view />
      </v-card>
    </v-card>
    <core-snackbar />
  </v-app>
</template>

<script>
  import eventsApi from '@/api/events'

  export default {
    components: {
      CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
    },

    data: () => ({
      loadingEvent: false,
      event: null,
    }),

    async mounted () {
      this.loadEvent()
    },

    methods: {
      async loadEvent () {
        try {
          this.loadingEvent = true
          const response = await eventsApi.get(this.$route.params.eventId)
          this.event = response.data.event
        } catch (e) {
          if (e?.response?.status === 404) {
            return this.$router.push(`/inscricao/${this.$route.params.eventId}/nao-encontrado`)
          }
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingEvent =false
        }
      },
    }
  }
</script>
