import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`events?${params}`)
  },

  async listBadgeFields () {
    return await axios.get(`badge-fields`)
  },

  async get (id) {
    return await axios.get(`events/${id}`)
  },

  async insert (data) {
    return await axios.post('events', data)
  },

  async update (id, data) {
    return await axios.put(`events/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`events/${id}`)
  },

}
